import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/index'
import order from '../views/order/index'
import queryOrder from '../views/order/queryOrder'
import whq from '../views/whq/index'
import whqForm from '../views/whq/form'
import whqFinish from '../views/whq/finish'
import whqAddExperience from '../views/whq/form/addExperience'
import whqAddFamily from '../views/whq/form/addFamily'
import drive from '../views/drive/index'
import driveFinish from '../views/drive/finish'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: home,
    meta: {
      title: '华普客户查询'
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: order,
    meta: {
      title: '订单支付'
    }
  },
  {
    path: '/order/queryOrder',
    name: 'queryOrder',
    component: queryOrder,
    meta: {
      title: '订单支付查询'
    }
  },
  {
    path: '/whq',
    name: 'whqIndex',
    component: whq,
    meta: {
      title: '欢迎加入华普财税(山东)有限公司'
    }
  },
  {
    path: '/whq/form',
    name: 'whqForm',
    component: whqForm,
    meta: {
      title: '华普财税(山东)入职登记表',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/whq/finish',
    name: 'whqFinish',
    component: whqFinish,
    meta: {
      title: '欢迎加入华普财税(山东)有限公司'
    }
  },
  {
    path: '/whq/form/addExperience',
    name: 'whqAddExperience',
    component: whqAddExperience,
    meta: {
      title: '欢迎加入华普财税(山东)有限公司'
    }
  },
  {
    path: '/whq/form/addFamily',
    name: 'whqAddFamily',
    component: whqAddFamily,
    meta: {
      title: '欢迎加入华普财税(山东)有限公司'
    }
  },
  {
    path: '/drive',
    name: 'driveIndex',
    component: drive,
    meta: {
      title: '提交自驾'
    }
  },
  {
    path: '/drive/finish',
    name: 'driveFinish',
    component: driveFinish,
    meta: {
      title: '提交成功'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
