<template>
  <div>
    <div style="width: 97%; line-height: 25px;margin: 0 auto; padding: 15px;">
        <div v-if="this.orderInfo.status == 3">
            <van-image
                width="100"
                height="100"
                :src="successImg"
                style="display: block;margin: 20px auto;"
                />
            <div style="text-align: center; font-weight: bolder; padding: 20px 0; letter-spacing: 1px;">支付成功</div>         
        </div>
        <div v-if="this.orderInfo.status == 5">
            <van-image
                width="100"
                height="100"
                :src="failImg"
                style="display: block;margin: 20px auto;"
                />
            <div style="text-align: center; font-weight: bolder; padding: 20px 0; letter-spacing: 1px;">支付异常</div>    
        </div>
        <div v-if="this.orderInfo.status == 0">
            <van-image
                width="100"
                height="100"
                :src="loadingImg"
                style="display: block;margin: 20px auto;"
                />
            <div style="text-align: center; font-weight: bolder; padding: 20px 0; letter-spacing: 1px;">待支付</div>
        </div>
        <div v-if="this.payState == null">
            <van-image
                width="100"
                height="100"
                :src="loadingImg"
                style="display: block;margin: 20px auto;"
                />
            <div style="text-align: center; font-weight: bolder; padding: 20px 0; letter-spacing: 1px;">正在查询订单支付状态</div>
        </div>
    </div>
    <div style="width: 97%; line-height: 25px;background-color: #FFF; border-radius: 5px; margin: 0 auto; padding: 5px;">
      <div>
        <span>订单编号: </span>
        <span class="field_vlaue">{{ this.orderInfo.orderSn}}</span>
      </div>
      <div>
        <span>商品说明: </span>
        <span class="field_vlaue">{{ this.orderInfo.description}}</span>
      </div>
      <div>
        <span>应付金额: </span>
        <span class="field_vlaue">{{ this.orderInfo.totalAmount}}</span>
      </div>

      <div>
        <span>优惠金额: </span>
        <span class="field_vlaue">{{ this.orderInfo.couponAmount}}</span>
      </div>

      <div>
        <span>实付金额: </span>
        <span style="color: #F88;">{{ this.orderInfo.payAmount}}</span>
      </div>
      <div>
        <span class="field_vlaue">*订单详情请返回华普财税APP查看</span>
      </div>
    </div>
    <div style="width: 90%; margin: 0 auto;" v-if="payState == false">
      <van-button plain type="primary" @click="closePage" style="display: block; width: 40%; margin: 20px auto; float:left;">返回公众号</van-button>
      <van-button type="primary" @click="startWeiXinPay" style="display: block; width: 40%; margin: 20px auto; float:left; margin-left: 15%;">立即支付</van-button>
    </div>
    <div style="width: 97%; margin: 0 auto;" v-if="payState == null || payState == true">
      <van-button plain type="primary" @click="closePage" style="display: block; width: 100%; margin: 20px auto; float:left;">返回公众号</van-button>
    </div>
    <div>{{ orderState }}</div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
import { Field, Row, Col, Button, Empty, List, Form, Sticky, Image } from 'vant'
Vue.use(Sticky)
Vue.use(Field)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Empty)
Vue.use(List)
Vue.use(Form)
Vue.use(Image)

export default {
  name: "order",
  data() {
    return {
      orderInfo: {},
      orderId: null,
      openid: null,
      orderState: null,
      payState: null, //支付状态
      successImg: require('@/assets/success.png'),
      failImg: require('@/assets/fail.png'),
      loadingImg: require('@/assets/loading.png')
    }
  },
  mounted: function() {
    var query=this.$route.query;

    this.orderId = query.orderId;
    this.openid = query.openid;
    console.log(this.orderId)
    this.getList(this.orderId);
  },
  methods: {
    startWeiXinPay() {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady())
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady())
        }
      } else {
        this.onBridgeReady()
      }
    },
    onBridgeReady() {
      const that = this;
      // 1. 获取支付参数
      Toast.loading({
        forbidClick: true
      })
      let data = {
        "appId":"",
        "nonceStr":"",
        "package":"",
        "signType":"RSA",
        "timeStamp":"",
        "paySign":""
      };
      this.$axios.get('http://ezhangbao.com:9999/ework-inside/order/prepay', {
        params: {
          orderId: this.orderId,
          openid: this.openid
        }
      }).then(res => {
        Toast.clear()
        data = res.data.data;
        data.package = data.packagee;
        delete data.packagee;
        console.log(data);
        // 2. 调起支付
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', data,
          function(res) {
            if (res.err_msg == 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
              // Toast.success('支付成功');
              // 跳转到下一页查询支付结果
              that.$router.push({path: '/order/queryOrder?orderId=' + that.orderId, query:{orderId: that.orderId}});
            } else if(res.err_msg == 'get_brand_wcpay_request:cancel') {
              Toast.fail('取消支付');
            } else if(res.err_msg == 'get_brand_wcpay_request:fail') {
              Toast.fail('支付失败');
            } else {
              Toast.fail('支付失败');
            }
          }
        )
      }).catch(() => {
        Toast.clear()
        Toast.fail('调起支付失败');
      })
      
    },
    getOrderState() {
      this.$router.push({path: '/order/queryOrder?orderId=' + this.orderId, query:{orderId: this.orderId}});
      // 接着使用以下方式查询支付结果
      // this.$axios.get('/ework-inside/order/state', {
      //   params: {
      //     orderId: this.orderId
      //   }
      // }).then(resp => {
      //   Toast.clear()
      //   this.orderState = resp.data;
      //   if(resp.data.data.trade_state == 'SUCCESS'){
      //     Toast.success('支付查询成功');
      //   } else {
      //     Toast.fail('支付失败');
      //   }
      // }).catch(() => {
      //   Toast.clear()
      //   Toast.fail('支付失败');
      // })
    },
    getList(orderId) {
      const that = this;
      console.log(123);
      Toast.loading({
        message: "查询支付状态",
        forbidClick: true
      })
      this.$axios.get('http://ezhangbao.com:9999/ework-inside/order', {
        params: {
          orderId: orderId
        }
      }).then(res => {
        Toast.clear()
        that.orderInfo = res.data.data;
        console.log(that.orderInfo);
        
        that.payState = res.data.data.status == 3 ? true : false;
        console.log(that.payState)
      }).catch(() => {
        Toast.clear()
        Toast.fail({
            message: "查询订单失败",
            forbidClick: true,
            duration: 2000
        })
      })
    },
    closePage(){
        setTimeout(function() {
            //这个可以关闭安卓系统的手机
            document.addEventListener("WeixinJSBridgeReady", function() {
                    window.WeixinJSBridge.call("closeWindow");
                },
                false
            );
            //这个可以关闭ios系统的手机
            window.WeixinJSBridge.call("closeWindow");
        }, 1);
    }
  }
}
</script>

<style scoped>

  .field_vlaue{
    color: #888;
  }
</style>
