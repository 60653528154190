<template>
  <div style="width: 100%; margin: 0; padding: 0;">
    <div style="width: 100%; height: 60px;  margin: 0 auto; z-index: 999; ">
      <van-steps :active="currentStep" style="margin-top: 0px;">
        <van-step>基本信息</van-step>
        <van-step>经验经历</van-step>
        <van-step>家庭成员</van-step>
        <van-step>身份证件</van-step>
      </van-steps>
    </div>
    <div style="width: calc(100vw - 10px); height:  calc(100vh - 80px); line-height: 25px;margin: 0 auto; padding-left: 10px; overflow-y: auto; ">
      <!--基本信息-->
      <div class="header" v-if="currentStep == 0">
        <div class="rect"></div>
        <div class="title">基本信息</div>
      </div>
      <van-form ref="basicForm" style="width: 97%;">
        <van-cell-group inset v-if="currentStep == 0">
          <van-field
            v-model="form.name"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field name="radio" label="性别" v-model="form.sex">
            <template #input>
              <van-radio-group direction="horizontal" v-model="form.sex">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="form.mobilePhone"
            name="pattern"
            label="手机号"
            placeholder="手机号"
            :rules="[{pattern: mobilPhoneRule, trigger: 'onBlur', message: '请填写手机号' }]"
          />
          <van-field
            v-model="form.cardTypeName"
            readonly
            clickable
            name="cardType"
            label="证件类型"
            placeholder="选择证件类型"
            @click="showcardTypePicker = true"
          />
          <van-field
            v-model="form.cardNumber"
            name="证件号"
            label="证件号"
            placeholder="证件号"
            :rules="[{ required: true, message: '请填写证件号' }]"
          />
          <van-field
            v-model="form.birthDate"
            readonly
            clickable
            name="calendar"
            label="生日"
            placeholder="点击选择生日"
            @click="showDatePop('birth')"
          />
          <van-field
            v-model="form.marryStatusName"
            readonly
            clickable
            name="marryStatusName"
            label="婚姻状况"
            placeholder="选择婚姻状况"
            @click="showmarryStatus = true"
            :rules="[{ required: true, message: '请填写婚姻状况' }]"
          />
          <van-field
            v-model="form.postName"
            readonly
            clickable
            name="postName"
            label="应聘岗位"
            placeholder="选择应聘岗位"
            @click="showPost = true"
            :rules="[{ required: true, message: '请选择应聘岗位' }]"
          />
          <van-field
            v-model="form.salaryRequire"
            name="薪资要求"
            label="薪资要求"
            placeholder="薪资要求"
            :rules="[{ required: true, message: '请填写薪资要求' }]"
          />
          <!-- <van-field
            v-model="form.add"
            name="籍贯"
            label="籍贯"
            type="textarea"
            :rules="[{ required: true, message: '请填写籍贯' }]"
          /> -->
          <van-field
            v-model="form.liveAddress"
            name="常住地址"
            label="常住地址"
            type="textarea"
            :rules="[{ required: true, message: '请填写常住地址' }]"
          />
          <van-field name="radio" label="是否有驾照" v-model="form.drivingLicense">
            <template #input>
              <van-radio-group direction="horizontal" v-model="form.drivingLicense">
                <van-radio name="0">无</van-radio>
                <van-radio name="1">有</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="form.motorcycleType"
            name="车型"
            label="车型"
            placeholder="车型"
            :rules="[{ message: '请填写车型' }]"
            v-if="form.drivingLicense == '1'"
          />
          <van-field
            v-model="form.plateNumber"
            name="车牌号"
            label="车牌号"
            placeholder="车牌号"
            :rules="[{ message: '请填写车牌号' }]"
            v-if="form.drivingLicense == '1'"
          />
          <van-field
            v-model="form.drivingYears"
            name="驾龄(年)"
            label="驾龄(年)"
            placeholder="驾龄(年)"
            :rules="[{ message: '请填写驾龄' }]"
            v-if="form.drivingLicense == '1'"
          />
          <van-field
            v-model="form.channel"
            name="了解渠道"
            label="了解渠道"
            placeholder="招聘信息了解渠道"
            :rules="[{ required: true, message: '请填写招聘信息了解渠道' }]"
          />
          <van-field
            v-model="form.arrivalTime"
            readonly
            clickable
            name="calendar"
            label="最快到岗时间"
            placeholder="点击选择最快到岗时间"
            @click="showDatePop('arrivalTime')"
          />
          <van-field
            v-model="form.selfAssessment"
            name="自我评价"
            label="自我评价"
            type="textarea"
            :rules="[{ required: true, message: '请填写自我评价' }]"
          />
        </van-cell-group>
      </van-form>
      <van-form style="width: 97%;">
        <!--教育经历-->
        <div class="header" v-if="currentStep == 1">
          <div class="rect"></div>
          <div class="title">教育经历</div>
        </div>
        <van-cell-group inset  v-if="currentStep == 1">
          <div v-for="(item, index) in experienceForm.eduList" :key="index" style="border-bottom: 10px solid #F3F4F7;">
            <div style=" padding: 10px; color: #888;">
              <div style="float: left;">{{'教育经历' + (index + 1)}}</div>
              <div style="float: right; color: #ef7878; font-size: 14px;" @click="delEdu(item.id, index)">删除</div>
            </div>
            <van-field
              v-model="item.dateRange.split(/——/g)[0]"
              name="开始日期"
              label="开始日期"
              :rules="[{ required: true, message: '请填写开始日期（年-月）' }]"
            />
            <van-field
              v-model="item.dateRange.split(/——/g)[1]"
              name="结束日期"
              label="结束日期"
              :rules="[{ required: true, message: '请填写结束日期（年-月）' }]"
            />
            <van-field
              v-model="item.unit"
              name="毕业院校"
              label="毕业院校"
            />
            <van-field
              v-model="item.major"
              name="专业"
              label="专业"
            />
            <van-field
              v-model="item.result"
              name="学历"
              label="学历"
            />
          </div>
          <div style="width: 100%; height: 60px; background-color: #F3F4F7;">
            <van-button plain block type="info" native-type="submit" icon="plus" @click="addEdu" style="letter-spacing: 1px;">
            增加一个教育经历
            </van-button>
          </div>

        </van-cell-group>

        <!--工作经历-->
        <div class="header" v-if="currentStep == 1">
          <div class="rect"></div>
          <div class="title">工作经历</div>
        </div>
        <van-cell-group inset v-if="currentStep == 1">
          <div v-for="(item, index) in experienceForm.workList" :key="index" style="border-bottom: 10px solid #F3F4F7;">
            <div style=" padding: 10px; color: #888;">
              <div style="float: left;">{{'工作经历' + (index + 1)}}</div>
              <div style="float: right; color: #ef7878; font-size: 14px;" @click="delWork(item.id, index)">删除</div>
            </div>
            <van-field
              v-model="item.dateRange.split(/——/g)[0]"
              name="开始日期"
              label="开始日期"
              :rules="[{ required: true, message: '请填写开始日期（年-月）' }]"
            />
            <van-field
              v-model="item.dateRange.split(/——/g)[1]"
              name="结束日期"
              label="结束日期"
              :rules="[{ required: true, message: '请填写结束日期（年-月）' }]"
            />
            <van-field
              v-model="item.unit"
              name="工作单位"
              label="工作单位"
            />
            <van-field
              v-model="item.major"
              name="职务"
              label="职务"
            />
            <van-field
              v-model="item.result"
              name="离职原因"
              label="离职原因"
              type="textarea"
            />
          </div>
          <div style="width: 100%; height: 60px; background-color: #F3F4F7;">
            <van-button plain block type="info" native-type="submit" icon="plus" @click="addWork" style="letter-spacing: 1px;">
            增加一个工作经历
            </van-button>
          </div>
        </van-cell-group>

        <!--家庭成员-->
        <div class="header" v-if="currentStep == 2">
          <div class="rect"></div>
          <div class="title">家庭成员</div>
        </div>
        <van-cell-group inset v-if="currentStep == 2">
          <div v-for="(item, index) in familyForm.familyList" :key="index" style="border-bottom: 10px solid #F3F4F7;">
            <div style=" padding: 10px; color: #888;">
              <div style="float: left;">{{'家庭成员' + (index + 1)}}</div>
              <div style="float: right; color: #ef7878; font-size: 14px;" @click="delFamilyMember(item.id, index)">删除</div>
            </div>
            <van-field
              v-model="item.name"
              name="姓名"
              label="姓名"
              :rules="[{ required: true, message: '请填写成员姓名' }]"
            />
            <van-field
              v-model="item.relation"
              name="关系"
              label="关系"
              :rules="[{ required: true, message: '请填写与您的关系' }]"
            />
            <van-field
              v-model="item.workUnit"
              name="工作单位"
              label="工作单位"
              :rules="[{ required: true, message: '请填写工作单位' }]"
            />
            <van-field
              v-model="item.job"
              name="职业"
              label="职业"
              :rules="[{ required: true, message: '请填写职业' }]"
            />
            <van-field
              v-model="item.phone"
              name="联系电话"
              label="联系电话"
              :rules="[{ required: true, message: '请填写联系电话' }]"
            />
          </div>
          <div style="width: 100%; height: 60px; background-color: #F3F4F7;">
            <van-button plain block type="info" native-type="submit" icon="plus" @click="addFamilyMember" style="letter-spacing: 1px;">
            增加一个家庭成员
            </van-button>
          </div>
        </van-cell-group>


        <!--紧急联系人-->
        <div class="header" v-if="currentStep == 2">
          <div class="rect"></div>
          <div class="title">紧急联系人</div>
        </div>
        <van-cell-group inset v-if="currentStep == 2">
          <div v-for="(item, index) in familyForm.emergencyList" :key="index" style="border-bottom: 10px solid #F3F4F7;">
            <div style=" padding: 10px; color: #888;">
              <div style="float: left;">{{'紧急联系人' + (index + 1)}}</div>
              <div style="float: right; color: #ef7878; font-size: 14px;" @click="delEmergencyContact(item.id, index)">删除</div>
            </div>
            <van-field
              v-model="item.name"
              name="联系人姓名"
              label="联系人姓名"
              :rules="[{ required: true, message: '请填写联系人姓名' }]"
            />
            <van-field
              v-model="item.relation"
              name="关系"
              label="关系"
              :rules="[{ required: true, message: '请填写与您的关系' }]"
            />
            <van-field
              v-model="item.phone"
              name="联系电话"
              label="联系电话"
              :rules="[{ required: true, message: '请填写联系电话' }]"
            />
          </div>
          <div style="width: 100%; height: 60px; background-color: #F3F4F7;">
            <van-button plain block type="info" native-type="submit" icon="plus" @click="addEmergencyContact" style="letter-spacing: 1px;">
            增加一个紧急联系人
            </van-button>
          </div>
        </van-cell-group>

        <!--证件/证书-->
        <div class="header" v-if="currentStep == 3">
          <div class="rect"></div>
          <div class="title">证件/证书</div>
        </div>
        <van-cell-group inset v-if="currentStep == 3">
          <van-field name="uploader" label="身份证头像面（或其他证件头像面）">
            <template #input>
              <van-uploader v-model="imgForm[0]" :after-read="beforeUpload" @oversize="oversize" @delete="beforeDelete" name="0"/>
            </template>
          </van-field>
          <van-field name="uploader" label="身份证国徽面">
            <template #input>
              <van-uploader v-model="imgForm[1]" :after-read="beforeUpload" @oversize="oversize" @delete="beforeDelete" name="1"/>
            </template>
          </van-field>
          <van-field name="uploader" label="毕业证">
            <template #input>
              <van-uploader v-model="imgForm[2]" :after-read="beforeUpload" @oversize="oversize" @delete="beforeDelete" name="2"/>
            </template>
          </van-field>
          <van-field name="uploader" label="离职证明">
            <template #input>
              <van-uploader v-model="imgForm[3]" :after-read="beforeUpload" @oversize="oversize" @delete="beforeDelete" name="3"/>
            </template>
          </van-field>
        </van-cell-group>
        <van-checkbox v-model="guarantee" style="width: 100%; height: 50px; line-height: 50px; margin-bottom: 10px; text-align: center;">本人承诺以上各项资料均真实有效</van-checkbox>
        <div style="margin: 16px;" v-if="currentStep == 1 || currentStep == 2">
          <div style="width: 15%; height: 60px; float:left; text-decoration: underline; color: #CCC; line-height: 40px;" @click="nextStep(true)">跳过</div>
          <van-button round block plain type="info" native-type="submit" @click="prevStep(false)" style="width: 25%; float: left; margin-left: 5%;">
          上一步
          </van-button>
          <van-button round block type="info" native-type="submit" @click="nextStep(false)" style="width: 50%; float: left; margin-left: 5%;">
          下一步
          </van-button>
        </div>
        <div style="margin: 16px;" v-if="currentStep == 0">
          <van-button round block type="info" native-type="submit" @click="nextStep(false)">
          下一步
          </van-button>
        </div>
        <div style="margin: 16px;" v-if="currentStep == 3">
          <div style="width: 15%; height: 60px; float:left; text-decoration: underline; color: #CCC; line-height: 40px;" @click="nextStep(true)">跳过</div>
          <van-button round block plain type="info" native-type="submit" @click="prevStep(false)" style="width: 25%; float: left; margin-left: 5%;">
          上一步
          </van-button>
          <van-button round block type="info" native-type="submit" @click="nextStep(false)" style="width: 50%; float: left; margin-left: 5%;">
          完成填写
          </van-button>
        </div>
      </van-form>
    </div>

    <!-- <div style="width: 100%; height: 100px; margin: 0 auto; position: fixed; bottom: 0; text-align: right; background-color: #FFF;">
      <van-button plain type="info"  style="display: block; width: 30%; margin: 20px auto; float:left;margin-left: 20px;">跳过当前页</van-button>
      <van-button type="info" style="display: block; width: 50%; margin: 20px auto; float:right; margin-left: 10px; margin-right: 20px;">下一步 > 填写教育经历</van-button>
    </div> -->
    <!--证件类型选择-->
    <van-popup v-model="showcardTypePicker" round position="bottom">
      <van-picker
        :columns="cardTypeList"
        show-toolbar
        value-key="label"
        @cancel="showcardTypePicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <!--婚姻状况选择-->
    <van-popup v-model="showmarryStatus" round position="bottom">
      <van-picker
        :columns="marryStatus"
        show-toolbar
        value-key="label"
        @cancel="showmarryStatus = false"
        @confirm="onConfirmMarital"
      />
    </van-popup>
    <!--应聘岗位选择-->
    <van-popup v-model="showPost" round position="bottom">
      <van-picker
        :columns="postList"
        show-toolbar
        value-key="label"
        @cancel="showPost = false"
        @confirm="onConfirmPost"
      />
    </van-popup>
    <!--出生日期选择-->
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onDateConfirm"
        @cancel="showDate = false"
      />
    </van-popup>
    <!--最快到岗日期选择-->
    <van-popup v-model="showArrivalDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minArrivalDate"
        :max-date="maxArrivalDate"
        @confirm="onDateConfirm"
        @cancel="showArrivalDate = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import api from './index'
import { Toast, Field, Row, Col, Button, Empty, List, Form, Sticky, Image, CellGroup, RadioGroup, Radio, Picker, Popup, Checkbox, CheckboxGroup, Step, Steps, Uploader, Calendar, DatetimePicker, Icon     } from 'vant'
Vue.use( Toast, Field, Row, Col, Button, Empty, List, Form, Sticky, Image, CellGroup, RadioGroup, Radio, Picker, Popup, Checkbox, CheckboxGroup, Step, Steps, Uploader, Calendar, DatetimePicker, Icon)


export default {
  name: "whqForm",
  data() {
    return {
      mobilPhoneRule: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      minDate: new Date(1950, 0, 1),
      maxDate: new Date(),
      minArrivalDate: new Date(),
      maxArrivalDate: new Date(2099, 12, 31),
      form: {
        name: '', // 用户名
        gender: '1', //
        mobilPhone: '', // 手机号
        cardType: '', // 证件类型
        cardTypeName: '', // 证件类型
        birthDate: '', // 出生日期
        marryStatus: '', // 婚姻状况
        marryStatusName: '', // 婚姻状况
        post: '', // 应聘岗位
        postName: '', // 应聘岗位
        salaryRequire: '', // 薪资要求
        cardNumber: '', // 证件号
        liveAddress: '', // 常住地址
        drivingLicense: '0', // 是否有驾照
        motorcycleType: '', // 车型
        plateNumber: '', // 车牌号
        drivingYears: '', // 驾龄
        channel: '', //了解渠道
        selfAssessment: '', // 自我评价
        arrivalTime: '', // 最快到岗时间
        channel: '', //了解渠道
        // 家庭成员经历
        familyMemberList: [
          {
            id: '',
            name: '',
            relation: '',
            company: '',
            position: '',
            mobilPhone: ''
          }
        ],
        // 紧急联系人
        emergencyContactList: [
          {
            id: '',
            name: '',
            relation: '',
            mobilPhone: ''
          }
        ],

      },
      // 教育、工作经历表单
      experienceForm: {
        // 教育经历
        eduList: [],
        // 工作经历
        workList: []

      },
      // 家庭成员、紧急联系人
      familyForm: {
        // 家庭成员
        familyList: [],
        // 紧急联系人
        emergencyList: []
      },
      // 证件
      imgForm: [
        // 身份证头像面
        [],
        // 身份证国徽面
        [],
        // 毕业证
        [],
        // 离职证明
        []
      ],
      successImg: require('@/assets/success.png'),
      failImg: require('@/assets/fail.png'),
      loadingImg: require('@/assets/loading.png'),
      showcardTypePicker: false, // 证件类型
      cardTypeList: [{value: 0, label: '居民身份证'}, {value: 1, label: '士官证'}, {value: 2, label: '学生证'}, {value: 3, label: '驾驶证'}, {value: 4, label: '护照'}, {value: 5, label: '港澳通行证'}],
      showmarryStatus: false, // 婚姻状况
      marryStatus: [],
      postList: [], // 应聘岗位列表
      guarantee: false,
      showDate: false,
      showArrivalDate: false,
      currentStep: 0,// 步骤
      currentDate: new Date(), // 现在时间
      type: 'datetime',
      currentDateField: '', // 当前正在修改的日期字段，用于表单多日期组件选择的情况
      newUserFlag: false, // 新用户标识，如果未保存过信息该标识为ture
      isFirstLoad: true, // 第一次加载标识
      showPost: false, // 弹出选择下拉框
      qiniuToken: null // 七牛token
    }
  },
  onShow(){
    // console.log(new Date().getTime() + "---onShow")
    if(!this.isFirstLoad) {
      if(this.currentStep == 1){
        this.loadExperience();
      }
    }
    this.isFirstLoad = false;
  },
  beforeRouteEnter(to, from, next) {
    // console.log("to--");
    // console.log(to);
    // console.log("from--");
    // console.log(from);
    if (from.name == 'whqAddExperience') {
      next(vm => {
        vm.loadExperience();
      })
    } else if (from.name == 'whqAddFamily') {
      next(vm => {
        vm.loadFamily();
      })
    } else {
      next();
    }

  },
  mounted: function() {
    // Toast(1);
    let _this = this;
    let id = this.$route.query.id;
    this.form.id = id;
    // 附录
    this.$axios.get('/ework-inside/dict/' + 'hr-marry-status' + '/value', {})
    .then(res => {
      if(res.data.code == 0){
        _this.marryStatus = res.data.data;
      }
    });
    this.$axios.get('/ework-inside/dict/' + 'hr-post' + '/value', {})
    .then(res => {
      if(res.data.code == 0){
        _this.postList = res.data.data;
      }
    });
    if(id == null || id == undefined) {
      this.newUserFlag = true;
    } else {
      // 加载用户数据
      Toast.loading({
        forbidClick: true
      })
      this.$axios.get('/ework-inside/hr/baseInfo/' + id, {
        params: {
          key: this.key,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber
        }
      }).then(res => {
        Toast.clear()
        this.form = res.data.data;
        // 证件类型名称
        this.cardTypeList.forEach(item => {
          if(item.value == this.form.cardType) {
            this.form.cardTypeName = item.label;
          }
        });
        // 婚姻状况名称
        this.marryStatus.forEach(item => {
          if(item.value == this.form.marryStatus) {
            this.form.marryStatusName = item.label;
          }
        });
        // 岗位名称
        this.postList.forEach(item => {
          if(item.value == this.form.post) {
            this.form.postName = item.label;
          }
        });

      }).catch(() => {
        Toast.clear()
      })
      this.loadCert();

    }
    // 获取上传token
    this.getToken('public').then(res => {
      this.qiniuToken = res.data.data;
    })

  },
  // beforeRouteEnter(to, from) {
  //   if (from.name == 'whqAddExperience') {
  //     // to.meta.isBack = true;
  //     this.loadExperience();
  //   }

  // },
  methods: {
    // 加载经历
    loadExperience() {
      Toast.loading({
        forbidClick: true
      });
      this.$axios.get('/ework-inside/hr/experience/list?baseInfoId=' + this.form.id + "&type=",   {
        params: {
          key: this.key,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber
        }
      }).then(res => {
        Toast.clear();
        if(res.data.data != null){
          let list = res.data.data;
          this.experienceForm.eduList = [];
          this.experienceForm.workList = [];
          list.forEach(item => {
            if(item.type == 1) {
              this.experienceForm.eduList.push(item);
            } else if(item.type == 2) {
              this.experienceForm.workList.push(item);
            }
          });
        }
      }).catch(() => {
        Toast.clear()
      })
    },
    // 加载家庭成员
    loadFamily() {
      Toast.loading({
        forbidClick: true
      });
      this.$axios.get('/ework-inside/hr/familyLink/list?baseInfoId=' + this.form.id + "&type=",   {
        params: {
          key: this.key,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber
        }
      }).then(res => {
        Toast.clear();
        if(res.data.data != null){
          let list = res.data.data;
          this.familyForm.familyList = [];
          this.familyForm.emergencyList = [];
          list.forEach(item => {
            if(item.type == 1) {
              this.familyForm.familyList.push(item);
            } else if(item.type == 2) {
              this.familyForm.emergencyList.push(item);
            }
          });
        }
      }).catch(() => {
        Toast.clear()
      })
    },
    // 加载身份证件
    loadCert() {
      let _this = this;
      Toast.loading({
        forbidClick: true
      });

      this.$axios.get('/ework-inside/file/list/fileR?bizId=' + this.form.id + "&type=hr-staff-base-file",   {})
        .then(res => {
          Toast.clear();

          if(res.data != null){
            let list = res.data;
            // _this.imgForm[0] = [];
            // _this.imgForm[1] = [];
            // _this.imgForm[2] = [];
            list.forEach(item => {
              item.url = item.urlPath;
              if(item.useType == 1) {
                _this.imgForm[0].push(item);
                //  _this.$forceUpdate();
              } else if(item.useType == 2) {
                _this.imgForm[1].push(item);
                //  _this.$forceUpdate();
              } else if(item.useType == 3) {
                _this.imgForm[2].push(item);
                //  _this.$forceUpdate();
              }
            });
          }

          // _this.$forceUpdate();
        }).catch(() => {
          Toast.clear()
        })
        this.$axios.get('/ework-inside/file/list/fileR?bizId=' + this.form.id + "&type=hr-staff-work-file", {})
        .then(res => {
          Toast.clear();

          if(res.data != null){
            let list = res.data;
            // _this.imgForm[3] = [];
            list.forEach(item => {
              item.url = item.urlPath;
              if(item.useType == 4) {
                _this.imgForm[3].push(item);
              }
            });
          }
          // _this.$forceUpdate();
        }).catch(() => {
          Toast.clear()
        })
    },
    //增加教育经历
    addEdu() {
      this.$router.push({name:'whqAddExperience',query: {id: this.form.id, type: '1'}})
    },
    // 删除教育经历
    delEdu(id, index) {
      let _this = this;
      Toast.loading({
        forbidClick: true
      })
      this.$axios.delete('/ework-inside/hr/experience/delete/' + id, this.form)
        .then(res => {
          Toast.clear();
          if(res.data.code == 0) {
            _this.experienceForm.eduList.splice(index, 1);
          }
        }).catch(() => {
          Toast.clear()
        })

    },
    //增加工作经历
    addWork() {
      this.$router.push({name:'whqAddExperience',query: {id: this.form.id, type: '2'}})
    },
    // 删除工作经历
    delWork(id, index) {
      let _this = this;
      Toast.loading({
        forbidClick: true
      })
      this.$axios.delete('/ework-inside/hr/experience/delete/' + id, this.form)
        .then(res => {
          Toast.clear();
          if(res.data.code == 0) {
            _this.experienceForm.workList.splice(index, 1);
          }
        }).catch(() => {
          Toast.clear()
        })
    },
    //增加家庭成员
    addFamilyMember() {
      this.$router.push({name:'whqAddFamily',query: {id: this.form.id, type: '1'}})
    },
    // 删除家庭成员
    delFamilyMember(id, index) {
      this.form.familyMemberList.splice(index, 1);
    },
    // 增加紧急联系人
    addEmergencyContact() {
      this.$router.push({name:'whqAddFamily',query: {id: this.form.id, type: '2'}})
    },
    // 删除紧急联系人
    delEmergencyContact(id, index) {
      this.form.emergencyContactList.splice(index, 1);
    },
    // 提交
    onSubmit(success, error){
      // 校验表单
      let _this = this;
      this.$refs.basicForm.validate()
        .then(() => {
          // 如果是新用户，直接保存
          if(this.newUserFlag) {
            // 新用户
            Toast.loading({
              forbidClick: true
            })
            this.$axios.post('/ework-inside/hr/baseInfo/add', this.form)
              .then(res => {
                Toast.clear();
                if(res.data.code == 0) {
                  _this.form.id = res.data.data;
                  success();
                } else if(res.data.code == -1) {
                  Toast(res.data.data);
                }
                // 加载用户数据
              }).catch((res) => {
                Toast(res.msg);
                Toast.clear()
                error();
              })
          } else {
            // 完善信息的用户需要修改
            Toast.loading({
              forbidClick: true
            })
            this.$axios.post('/ework-inside/hr/baseInfo/modify', this.form)
              .then(res => {
                Toast.clear();
                if(res.data.code == 0) {
                  success();
                }
                // 加载用户数据
              }).catch(() => {
                Toast.clear()
                error();
              })
          }
        })
        .catch(() => {
          Toast("请按要求填写表单");
        });

    },
    // 证件类型
    onConfirm(val){
      this.showcardTypePicker = false;
      this.form.cardType = val.value;
      this.form.cardTypeName = val.label;
    },
    // 婚姻状况
    onConfirmMarital(val) {
      this.showmarryStatus = false;
      this.form.marryStatus = val.value;
      this.form.marryStatusName = val.label;
    },
    // 应聘岗位
    onConfirmPost(val) {
      this.showPost = false;
      this.form.post = val.value;
      this.form.postName = val.label;
    },
    // 保存并进入下一步
    prevStep(){
      this.currentStep--;
    },
    // 保存并进入下一步
    nextStep(flag){
      let _this = this;
      // 如果flag为true, 则不保存直接跳过
      // 如果是在经历界面，加载家庭成员
      if(!flag) {
        if(!this.guarantee) {
          Toast("请点击下方承诺按钮");
          return false;
        }
      }
      if(this.currentStep == 1) {
        flag = true;
        this.loadFamily();
      }
      // 如果是在经历界面，加载家庭成员
      if(this.currentStep == 2) {
        flag = true;
      }
      if(this.currentStep == 3) {
        flag = true;
      }
      if(flag) {
        if(this.currentStep < 3) {
          this.currentStep++;
        } else {
          // 进入完成界面
          this.$router.push({ path: '/whq/finish', query:{} });
        }
      } else {
        this.onSubmit(() => {
          if(_this.currentStep == 0) {
            _this.currentStep++;
             // 进入经历界面加载经历
            if(this.currentStep == 1) {
              this.loadExperience();
            }
          } else {
            // 进入完成界面
            _this.$router.push({ path: '/whq/finish', query:{} });
          }

        }, () => {
          // 保存失败
        });
      }

    },
    onDateConfirm(val){
      this.showDate = false;
      this.showArrivalDate = false;

      let dateValue = ''
      dateValue = this.formatDate(val, 'yyyy-MM-dd')
      if(this.currentDateField == 'birth') {
        this.form.birthDate = dateValue;
      } else if(this.currentDateField == 'arrivalTime') {
        this.form.arrivalTime = dateValue;
      } else {
        if(this.currentDateField.indexOf('List-') != -1){
          let arr = this.currentDateField.split(/-/g);
          this.form[arr[0]][arr[1]][arr[2]] = dateValue;
        }
      }
    },
    formatDate(val, format) {
      const REGEX = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/
      if (val) {
        /**
         * @instructions 如果不是时间戳格式，且含有字符 '-' 则将 '-' 替换成 '/' && 删除小数点及后面的数字
         * @reason 将 '-' 替换成 '/' && 删除小数点及后面的数字 的原因是safari浏览器仅支持 '/' 隔开的时间格式
         */
        if (val.toString().indexOf('-') > 0) {
          val = val.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').replace(/(-)/g, '/') // 将 '-' 替换成 '/'
          val = val.slice(0, val.indexOf('.')) // 删除小数点及后面的数字
        }
        const date = new Date(val)
        date.setHours(date.getHours() + 8)
        // eslint-disable-next-line no-unused-vars
        const [whole, yy, MM, dd, hh, mm, ss] = date.toISOString().match(REGEX)
        if (format) {
          return format
            .replace('yyyy', yy)
            .replace('yy', yy.slice(2))
            .replace('MM', MM)
            .replace('dd', dd)
            .replace('hh', hh)
            .replace('mm', mm)
            .replace('ss', ss)
        } else {
          return [yy , MM , dd].join('-') + ' ' + [hh, mm, ss].join(':')
        }
      } else {
        return '--'
      }
    },
    showDatePop(obj){
      if(obj == 'birth'){
        this.showDate = true;
      } else {
        this.showArrivalDate = true;
      }
      this.currentDateField = obj;
    },
    // 文件上传相关
    getToken(type) {
      return this.$axios({
        url: '/ework-admin/file/upload-token/' + type,
        method: 'get',
        hideloading: false
      })
    },
    upFile(params) {
      return this.$axios({
        url: 'http://upload.qiniup.com',
        method: 'post',
        data: params,
        hideloading: false,
        headers: { 'Content-Type': 'multipart/form-data;' }
      })
    },
    oversize() {
      this.$toast.fail('图片大小不能超过5MB！')
    },
    beforeUpload(file, detail) {
      let _this = this;
      const param = new FormData()
      param.append('key', `${new Date().getTime() + file.file.size}.${file.file.name.substring(file.file.name.lastIndexOf('.') + 1)}`)
      param.append('file', file.file)
      param.append('token', this.qiniuToken)
      // 检查每种证件是否有文件，有的话删除
      let delList = _this.imgForm[detail.name].splice(0, _this.imgForm[detail.name].length - 1);
      this.upFile(param).then(resp => {
        const obj = {
          fileKey: resp.data.key,
          fileName: file.file.name,
          handoverFormType: detail.name
        }
        let bizType = detail.name == 0 || detail.name == 1 || detail.name == 2 ? 'hr-staff-base-file' : 'hr-staff-work-file';
        let type = 1;
        switch(detail.name){
          case '0': type = 1; break;
          case '1': type = 2; break;
          case '2': type = 3; break;
          case '3': type = 4; break;
        }
        // 将文件入库
        _this.saveFileR({
          bizId: _this.form.id,
          bizType: bizType,
          type: type,
          fileUrl: obj.fileKey,
          fileName: obj.fileName
        }).then(res => {
          if(res.status == 200){
            Toast("上传成功");
            // 多余的文件删掉
            // debugger
            delList.forEach(item => {
              _this.deleteFileR(item.id);
            })
          }
        })
      })
    },
    // 保存文件信息
    saveFileR(params){
      return this.$axios({
        url: '/ework-inside/file/addFileR',
        method: 'post',
        data: params,
        hideloading: false
      })
    },
    // 删除文件信息
    deleteFileR(id){
      return this.$axios({
        url: '/ework-inside/file/remote?id=' + id,
        method: 'delete',
        hideloading: false
      })
    },
    beforeDelete(res) {
      this.filesList = this.filesList.filter((item) => {
        return item.fileName != res.file.name
      })
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.header>.rect {
  width: 5px;
  height: 15px;
  background: #0486FE;
  float: left;
  margin-top: 17px;
}
.header>.title {
  width: 100px;
  height: 20px;
  float: left;
  margin-left: 10px;
  font-size: 18px;
}

.field_vlaue{
  color: #888;
}

</style>
