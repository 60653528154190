<template>
  <div>
    <div :style="'width: 100%; margin: 0; padding: 0; background-image:url(' + entry + '); background-position: top;background-repeat:no-repeat; padding-top: 150px; background-size: 100%;background'">
      <div style="width: 92%; height: 200px;  margin: 0 auto;   background-color: #FFF; border-radius: 4px; border: 1px solid #EBEDF0; padding-top: 20px; line-height: 30px; text-align: center;">
        <van-image width="100" height="100" :src="finish"  style="display: block; width: 100px; height: 100px; margin: 0 auto; "/>
        <p style="width: 100%; text-align: center; font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600; color: #323233; line-height: 18px;">信息提交成功</p>

      </div>

    </div>
  </div>

</template>

<script>
import Vue from 'vue'
// import { Toast } from 'vant'
import { Image as VanImage } from 'vant';
import { Field, Row, Col, Button, Empty, List, Form, Sticky, Image, CellGroup, RadioGroup, Radio, Picker, Popup, Toast, Checkbox, CheckboxGroup, Step, Steps, Uploader   } from 'vant'
Vue.use(Sticky)
Vue.use(Field)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Empty)
Vue.use(List)
Vue.use(Form)
Vue.use(Image)
Vue.use(CellGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Toast );
Vue.use(CheckboxGroup );
Vue.use(Checkbox );
Vue.use(Steps);
Vue.use(Step);
Vue.use(Uploader);
Vue.use(VanImage);



export default {
  name: "driveFinish",
  data() {
    return {
      entry: require('@/assets/drive.png'),
      finish: require('@/assets/finish.png'),
    }
  },
  mounted: function() {

  },
  methods: {
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.header>.rect {
  width: 5px;
  height: 15px;
  background: #0486FE;
  float: left;
  margin-top: 17px;
}
.header>.title {
  width: 100px;
  height: 20px;
  float: left;
  margin-left: 10px;
  font-size: 18px;
}

.field_vlaue{
  color: #888;
}

</style>
