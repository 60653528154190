/*
 * @Description:
 * @version: 2.0
 * @Author: XiaTian
 * @Date: 2022-04-28 14:05:51
 * @LastAuthor: XiaTian
 * @LastTime: 2022-04-28 15:08:18
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueWechatTitle from 'vue-wechat-title'

Vue.config.productionTip = false

Vue.use(VueWechatTitle)

const Axios = axios.create({
  baseURL: 'http://www.ezhangbao.com',
  // baseURL: 'http://192.168.1.19',
  withCredentials: false,
  timeout: 10000
})

Vue.prototype.$axios = Axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title == undefined?'华普客户查询':to.meta.title
    next();
})
