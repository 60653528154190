<template>
  <div style="padding: 10px;">
    <van-sticky>
      <van-form @submit="search">
        <van-field v-model="key" style="font-size: 16px" size="large" placeholder="可输入客户名称、谐音、拼音、电话" :rules="[{
              required: true,
              message: '请输入查询信息'
            },{
              validator: (val) => { return val.length>=2},
              message: '请输入长度至少两位以上的字符'
            }]" clearable @clear="clearList">
          <template #button>
            <van-button size="small" type="info" style="font-size: 16px" native-type="submit">搜索</van-button>
          </template>
        </van-field>
      </van-form>
    </van-sticky>
    <van-empty v-if="list.length === 0" description="暂无数据" style="font-size: 18px" />
    <van-list v-else v-model="listLoading" :finished="listFinished" finished-text="没有更多了">
      <div class="customerList">
        <div class="cell" v-for="item in list" :key="item.id">
          <div class="row" style="font-size: 18px;color: #303133;font-weight: bold">
            <span>{{ item.customerName }}</span>
          </div>
          <div v-if="item.contactUser" class="row">
            <span>经理姓名：</span>
            <span style="color: #303133">{{ item.contactUser }}</span>
          </div>
          <div v-if="item.contactUserPhone" class="row">
            <span>公司电话：</span>
            <span style="color: #303133">{{ item.contactUserPhone }}</span>
          </div>
          <div v-if="item.accountantGroup" class="row">
            <span>所属组别：</span>
            <span style="color: #303133">{{ item.accountantGroup }}</span>
          </div>
          <div v-if="item.accountant" class="row">
            <span>服务会计：</span>
            <span style="color: #303133">{{ item.accountant }}</span>
          </div>
          <div v-if="item.accountantPhone" class="row">
            <span>会计电话：</span>
            <span style="color: #303133">{{ item.accountantPhone }}</span>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
import { Field, Row, Col, Button, Empty, List, Form, Sticky } from 'vant'
Vue.use(Sticky)
Vue.use(Field)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Empty)
Vue.use(List)
Vue.use(Form)

export default {
  name: "home",
  data() {
    return {
      key: '',
      list: [],
      listLoading: false,
      listFinished: false,
      pageSize: 20,
      pageNumber: 1
    }
  },
  methods: {
    getList() {
	  // 2023年6月6日 取消分页
      Toast.loading({
        forbidClick: true
      })
      this.$axios.get('/ework-inside/customer/query', {
        params: {
          key: this.key,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber
        }
      }).then(res => {
        Toast.clear()
		this.listLoading = false
        /* this.list = this.list.concat(res.data.data.data)
        this.pageNumber += 1
        if (res.data.data.data.length < this.pageSize) {
          this.listFinished = true
        } */
		this.list = res.data.data.data
		this.listFinished = true
      }).catch(() => {
        Toast.clear()
      })
    },
    search() {
      this.list = []
      this.pageNumber = 1
      this.listFinished = false
      this.getList()
    },
    clearList() {
      this.list = []
      this.pageNumber = 1
      this.listFinished = false
    }
  }
}
</script>

<style scoped>
  .customerList{
    margin-top: 16px;
    background-color: #FFF;
    border-radius: 4px;
    padding: 10px 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .cell{
    margin: 0 10px;
    font-size: 16px;
    color: #909399;
    border-bottom: 1px solid #DCDFE6;
    padding: 8px 0;
  }

  .row{
    padding: 4px 0;
  }
</style>
