<template>
  <div :style="'width: 100%; margin: 0; padding: 0; background-image:url(' + entry + '); background-position: top;background-repeat:no-repeat; padding-top: 150px; background-size: 100%;'">
    <div v-if="type == 0" style="width: 70%; height: 450px;  margin: 0 auto; z-index: 999;  background-color: #FFF; border-radius: 4px; border: 1px solid #EBEDF0; padding: 20px; line-height: 30px;">
      <p style="width: 100%; height: 18px; font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600; color: #323233; line-height: 18px; text-align: center;">欢迎加入</p>
      <p>亲爱的小伙伴：</p>
      <p style="text-indent:2em;">为确保HR及时通知您面试，请尽可能完整地填写面试登记表，并确保您所填写的信息准确、真实、有效。</p>
      <p style="text-indent:2em;">愿我们携手同行、共创辉煌！</p>
     
      <van-button block type="primary" native-type="submit" @click="nextStep" style="width: 90%; margin-top: 60px; margin: 0 auto; letter-spacing: 2px;">
      立即填写
      </van-button>
      <p style="height: 18px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #969696; line-height: 18px; margin-top: 60px; text-align: center;">Powered by 华普财税（山东）有限公司</p>
      
    </div>

  <div v-if="type == 1" style="width: 70%; height: 450px;  margin: 0 auto; z-index: 999;  background-color: #FFF; border-radius: 4px; border: 1px solid #EBEDF0; padding: 20px; line-height: 30px;">
      <p style="width: 100%; height: 18px; font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600; color: #323233; line-height: 18px; text-align: center;">欢迎加入</p>
      <p>亲爱的小伙伴：</p>
      <p style="text-indent:2em;">为方便HR给您办理入职手续，请尽可能完整地填写入职登记表和入职材料，并确保您所填写的信息准确、真实、有效。</p>
      <p style="text-indent:2em;">愿我们携手同行、共创辉煌！</p>
     
      <van-button block type="primary" native-type="submit" @click="nextStep" style="width: 90%; margin-top: 60px; margin: 0 auto; letter-spacing: 2px;">
      立即填写
      </van-button>
      <p style="height: 18px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #969696; line-height: 18px; margin-top: 60px; text-align: center;">Powered by 华普财税（山东）有限公司</p>
      
    </div>
     
  </div>
</template>

<script>
import Vue from 'vue'
// import { Toast } from 'vant'
import { Field, Row, Col, Button, Empty, List, Form, Sticky, Image, CellGroup, RadioGroup, Radio, Picker, Popup, Toast, Checkbox, CheckboxGroup, Step, Steps, Uploader   } from 'vant'
Vue.use(Sticky)
Vue.use(Field)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Empty)
Vue.use(List)
Vue.use(Form)
Vue.use(Image)
Vue.use(CellGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Toast );
Vue.use(CheckboxGroup );
Vue.use(Checkbox );
Vue.use(Steps);
Vue.use(Step);
Vue.use(Uploader);



export default {
  name: "whq",
  data() {
    return {
      form: {
        username: '', // 用户名
        gender: '1', //
        phone: '',
        IDType: '',
        birthdate: ''
      },
      entry: require('@/assets/entry.png'),
      failImg: require('@/assets/fail.png'),
      loadingImg: require('@/assets/loading.png'),
      showIDTypePicker: false, // 证件类型
      IDTypeList: ['身份证', '军官证'],
      guarantee: false,
      currentStep: 0, // 步骤
      type: 0 // 0 面试， 1 邀请
    }
  },
  mounted: function() {
    if(this.$route.query.type){
      this.type = this.$route.query.type;
    }
  },
  methods: {
    // 提交
    onSubmit(){

    },
    // 证件类型
    onConfirm(){

    },
    // 立即填写
    nextStep(){
      let id = this.$route.query.id;
      this.$router.push({ path: '/whq/form', query:{id: id} });
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%; 
  height: 50px; 
  line-height: 50px;
}
.header>.rect {
  width: 5px; 
  height: 15px; 
  background: #0486FE; 
  float: left; 
  margin-top: 17px;
}
.header>.title {
  width: 100px; 
  height: 20px; 
  float: left; 
  margin-left: 10px; 
  font-size: 18px;
}

.field_vlaue{
  color: #888;
}

</style>
