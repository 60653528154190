<template>
    <div style="background: #fff">
        <div class="header" :style="'background-image:url(' + drive + ')'"></div>
        <div class="tip">
            <div class="tip_top">自驾车费用补助登记表</div>
            <div class="tip_bot">请各位同事认真填写表单信息</div>
        </div>
        <div class="form">
            <van-form ref="dirveForm" style="width: 97%;">
                <van-field
                        v-model="form.out_time"
                        readonly
                        clickable
                        name="外出时间"
                        label="外出时间"
                        placeholder="请输入外出时间"
                        :rules="[{ required: true, message: '请填写外出时间' }]"
                        @click="showDatePop()"
                />
                <van-field
                        v-model="form.name"
                        name="姓名"
                        label="姓名"
                        placeholder="请输入姓名"
                        :rules="[{ required: true, message: '请填写姓名' }]"
                />
                <van-field
                        v-model="form.dept_name"
                        name="所在组"
                        label="所在组"
                        placeholder="请输入组名"
                        :rules="[{ required: true, message: '请填写组名' }]"
                />
                <van-field
                        v-model="form.matter"
                        name="外出事项"
                        label="外出事项"
                        placeholder="请输入外出事项"
                        type="textarea"
                        :rules="[{ required: true, message: '请填写外出事项' }]"
                />
                <van-field
                        v-model="form.mileage"
                        name="外出公里数"
                        label="外出公里数"
                        placeholder="请输入外出公里数"
                        :rules="[{ required: true, message: '请填写外出公里数' }]"
                />
                <van-field name="uploader" label="外出里程图片" :rules="[{ required: true, message: '请上传外出里程图片' }]">
                    <template #input>
                        <van-uploader v-model="outImgList" :max-count="1" :after-read="beforeUploadOut" @oversize="oversize" @delete="beforeDeleteOut"/>
                    </template>
                </van-field>
                <van-field name="uploader" label="归程里程图片" :rules="[{ required: true, message: '请上传归程里程图片' }]">
                    <template #input>
                        <van-uploader v-model="returnImgList" :max-count="1" :after-read="beforeUploadReturn" @oversize="oversize" @delete="beforeDeleteReturn"/>
                    </template>
                </van-field>
                <van-button round block type="info" style="margin-top: 20px" color="#FA6400" native-type="submit" @click="submit()">
                    提交
                </van-button>
            </van-form>
        </div>
        <!--外出时间选择-->
        <van-popup v-model="showDate" position="bottom">
            <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    @confirm="onDateConfirm"
                    @cancel="showDate = false"
            />
        </van-popup>
    </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Field, Row, Col, Button, Empty, List, Form, Sticky, Image, CellGroup, RadioGroup, Radio, Picker, Popup, Checkbox, CheckboxGroup, Step, Steps, Uploader, Calendar, DatetimePicker, Icon     } from 'vant'
Vue.use( Toast, Field, Row, Col, Button, Empty, List, Form, Sticky, Image, CellGroup, RadioGroup, Radio, Picker, Popup, Checkbox, CheckboxGroup, Step, Steps, Uploader, Calendar, DatetimePicker, Icon);



export default {
  name: "drive",
  data() {
    return {
        showDate: false,
        form: {
          out_time: null,
          name: null,
          dept_name: null,
          matter: null,
          mileage: null,
          out_img: null,
          return_img: null
        },
        drive: require('@/assets/drive.png'),
        currentDate: new Date(),
        outImgList: [],
        returnImgList: [],
        qiniuToken: null
    }
  },
  mounted: function() {
      // 获取上传token
      this.getToken('public').then(res => {
          this.qiniuToken = res.data.data;
      })
  },
  methods: {
      showDatePop(obj){
          this.showDate = true;
      },
      onDateConfirm(val){
          this.showDate = false;

          let dateValue = ''
          dateValue = this.formatDate(val, 'yyyy-MM-dd')
          this.form.out_time = dateValue;
      },
      formatDate(val, format) {
          const REGEX = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/
          if (val) {
              /**
               * @instructions 如果不是时间戳格式，且含有字符 '-' 则将 '-' 替换成 '/' && 删除小数点及后面的数字
               * @reason 将 '-' 替换成 '/' && 删除小数点及后面的数字 的原因是safari浏览器仅支持 '/' 隔开的时间格式
               */
              if (val.toString().indexOf('-') > 0) {
                  val = val.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').replace(/(-)/g, '/') // 将 '-' 替换成 '/'
                  val = val.slice(0, val.indexOf('.')) // 删除小数点及后面的数字
              }
              const date = new Date(val)
              date.setHours(date.getHours() + 8)
              // eslint-disable-next-line no-unused-vars
              const [whole, yy, MM, dd, hh, mm, ss] = date.toISOString().match(REGEX)
              if (format) {
                  return format
                      .replace('yyyy', yy)
                      .replace('yy', yy.slice(2))
                      .replace('MM', MM)
                      .replace('dd', dd)
                      .replace('hh', hh)
                      .replace('mm', mm)
                      .replace('ss', ss)
              } else {
                  return [yy , MM , dd].join('-') + ' ' + [hh, mm, ss].join(':')
              }
          } else {
              return '--'
          }
      },
      beforeUploadOut(file, detail) {
          let _this = this;
          const param = new FormData()
          param.append('key', `${new Date().getTime() + file.file.size}.${file.file.name.substring(file.file.name.lastIndexOf('.') + 1)}`)
          param.append('file', file.file)
          param.append('token', this.qiniuToken)
          this.upFile(param).then(resp => {
              _this.form.out_img = 'http://public-file.ezhangbao.com/' + resp.data.key;
          })
      },
      beforeUploadReturn(file, detail) {
          let _this = this;
          const param = new FormData()
          param.append('key', `${new Date().getTime() + file.file.size}.${file.file.name.substring(file.file.name.lastIndexOf('.') + 1)}`)
          param.append('file', file.file)
          param.append('token', this.qiniuToken)
          this.upFile(param).then(resp => {
              _this.form.return_img = 'http://public-file.ezhangbao.com/' + resp.data.key;
          })
      },
      // 文件上传相关
      getToken(type) {
          return this.$axios({
              url: '/ework-admin/file/upload-token/' + type,
              method: 'get',
              hideloading: false
          })
      },
      upFile(params) {
          return this.$axios({
              url: 'http://upload.qiniup.com',
              method: 'post',
              data: params,
              hideloading: false,
              headers: { 'Content-Type': 'multipart/form-data;' }
          })
      },
      oversize() {
          this.$toast.fail('图片大小不能超过5MB！')
      },
      // 保存文件信息
      saveFileR(params){
          return this.$axios({
              url: '/ework-inside/file/addFileR',
              method: 'post',
              data: params,
              hideloading: false
          })
      },
      beforeDeleteOut(res) {
          this.form.out_img = null;
      },
      beforeDeleteReturn(res) {
          this.form.return_img = null;
      },
      submit(){
          let _this = this;
          this.$refs.dirveForm.validate()
              .then(() => {
                  Toast.loading({
                      forbidClick: true
                  });
                  this.$axios.post('/hr/Self_driving_registration/add', _this.form)
                      .then(res => {
                          Toast.clear();
                          if(res.data.code == 0) {
                              _this.nextStep();
                          } else if(res.data.code == -1) {
                              Toast(res.data.msg);
                          }
                          // 加载用户数据
                      }).catch((res) => {
                      Toast(res.data.msg);
                      Toast.clear();
                      error();
                  })
              });
      },
      // 立即填写
      nextStep(){
          this.$router.push({ path: '/drive/finish' });
      }
  }
}
</script>

<style scoped>
.header {
    width: 100%;
    height: 250px;
    background-size: cover;
}
.tip{
    width: 88%;
    height: 62px;
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(205,205,205,0.50);
    border-radius: 9px;
    margin: -40px auto 0;
    padding: 20px 0 28px 20px;
}
.tip_top{
    font-size: 20px;
    font-weight: 500;
    color: rgb(50, 50, 51);
    margin-bottom: 14px;
}
.tip_bot{
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
}
.form{
    margin-top: 20px;
}
form{
    width: 100% !important;
}
</style>
