<template>
  <div :style="'width: 100%; margin: 0; padding: 0; background-image:url(' + entry + '); background-position: top;background-repeat:no-repeat; padding-top: 150px; background-size: 100%;'">
    <div style="width: 70%; height: 450px;  margin: 0 auto;   background-color: #FFF; border-radius: 4px; border: 1px solid #EBEDF0; padding: 20px; line-height: 30px; text-align: center;">
      <van-image width="100" height="100" :src="finish"  style="display: block; width: 100px; height: 100px; margin: 0 auto; "/>
      <p style="width: 100%; text-align: center; font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600; color: #323233; line-height: 18px;">提交成功</p>
      <p style="height: 18px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #969696; line-height: 18px; margin-top: 280px; text-align: center;">Powered by 华普财税（山东）有限公司</p>

    </div>
     
  </div>
</template>

<script>
import Vue from 'vue'
// import { Toast } from 'vant'
import { Image as VanImage } from 'vant';
import { Field, Row, Col, Button, Empty, List, Form, Sticky, Image, CellGroup, RadioGroup, Radio, Picker, Popup, Toast, Checkbox, CheckboxGroup, Step, Steps, Uploader   } from 'vant'
Vue.use(Sticky)
Vue.use(Field)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Empty)
Vue.use(List)
Vue.use(Form)
Vue.use(Image)
Vue.use(CellGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Toast );
Vue.use(CheckboxGroup );
Vue.use(Checkbox );
Vue.use(Steps);
Vue.use(Step);
Vue.use(Uploader);
Vue.use(VanImage);



export default {
  name: "whqFinish",
  data() {
    return {
      form: {
        username: '', // 用户名
        gender: '1', //
        phone: '',
        IDType: '',
        birthdate: ''
      },
      entry: require('@/assets/entry.png'),
      finish: require('@/assets/finish.png'),
      loadingImg: require('@/assets/loading.png'),
      showIDTypePicker: false, // 证件类型
      IDTypeList: ['身份证', '军官证'],
      guarantee: false,
      currentStep: 0 // 步骤
    }
  },
  mounted: function() {

  },
  methods: {
    // 提交
    onSubmit(){

    },
    // 证件类型
    onConfirm(){

    },
    // 保存并进入下一步
    nextStep(){
      if(this.currentStep < 3) {
        this.currentStep++;
      } else {
        // 进入完成界面
      }
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%; 
  height: 50px; 
  line-height: 50px;
}
.header>.rect {
  width: 5px; 
  height: 15px; 
  background: #0486FE; 
  float: left; 
  margin-top: 17px;
}
.header>.title {
  width: 100px; 
  height: 20px; 
  float: left; 
  margin-left: 10px; 
  font-size: 18px;
}

.field_vlaue{
  color: #888;
}

</style>
