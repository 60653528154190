<template>
  <div>
    <div style="width: 97%; line-height: 25px;margin: 0 auto; padding: 15px;">
        <div v-if="payState != null && payState == true">
            <van-image
                width="100"
                height="100"
                :src="successImg"
                style="display: block;margin: 20px auto;"
                />
            <div style="text-align: center; font-weight: bolder; padding: 20px 0; letter-spacing: 1px;">支付成功</div>         
        </div>
        <div v-if="payState != null && payState == false">
            <van-image
                width="100"
                height="100"
                :src="failImg"
                style="display: block;margin: 20px auto;"
                />
            <div style="text-align: center; font-weight: bolder; padding: 20px 0; letter-spacing: 1px;">支付异常</div>    
        </div>
        <div v-if="payState == null">
            <van-image
                width="100"
                height="100"
                :src="loadingImg"
                style="display: block;margin: 20px auto;"
                />
            <div style="text-align: center; font-weight: bolder; padding: 20px 0; letter-spacing: 1px;">正在查询订单支付状态</div>
        </div>
    </div>
    <div style="width: 97%; line-height: 25px;background-color: #FFF; border-radius: 5px; margin: 0 auto; padding: 5px;">
        
        <div>
            <span>订单编号: </span>
            <span class="field_vlaue">{{ this.orderInfo.orderSn}}</span>
        </div>
        <div>
            <span>商品说明: </span>
            <span class="field_vlaue">{{ this.orderInfo.description}}</span>
        </div>
        <div>
            <span>应付金额: </span>
            <span class="field_vlaue">{{ this.orderInfo.totalAmount}}</span>
        </div>

        <div>
            <span>优惠金额: </span>
            <span class="field_vlaue">{{ this.orderInfo.couponAmount}}</span>
        </div>

        <div>
            <span>实付金额: </span>
            <span style="color: #F88;">{{ this.orderInfo.payAmount}}</span>
        </div>
    </div>
    <van-button type="primary"  style="display: block; width: 100%; margin: 20px auto;" @click="closePage">返回公众号</van-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
import { Field, Row, Col, Button, Empty, List, Sticky, Image } from 'vant'
Vue.use(Sticky)
Vue.use(Field)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Empty)
Vue.use(List)
Vue.use(Image)

export default {
  name: "order",
  data() {
    return {
      orderInfo: {},
      orderId: null,
      payState: null, //支付状态
      successImg: require('@/assets/success.png'),
      failImg: require('@/assets/fail.png'),
      loadingImg: require('@/assets/loading.png')
    }
  },
  mounted: function() {
    var query=this.$route.query;
    this.orderId = query.orderId;
    this.openid = query.openid;
    this.getList(this.orderId);
  },
  methods: {
    getList(orderId) {
        const that = this;
      Toast.loading({
        message: "查询支付状态",
        forbidClick: true
      })
      this.$axios.get('http://ezhangbao.com:9999/ework-inside/order/state', {
        params: {
          orderId: orderId
        }
      }).then(res => {
        Toast.clear()
        that.orderInfo = res.data.data;
        console.log(that.orderInfo);
        
        that.payState = res.data.data.status == 3 ? true : false;
        console.log(that.payState)
        // if(that.payState) {
        //     Toast.success('支付成功');
        // } else {
        //     Toast.success('支付成功');
        // }
      }).catch(() => {
        Toast.clear()
        Toast.fail({
            message: "查询订单失败",
            forbidClick: true,
            duration: 2000
        })
      })
    },
    closePage(){
        setTimeout(function() {
            //这个可以关闭安卓系统的手机
            document.addEventListener("WeixinJSBridgeReady", function() {
                    window.WeixinJSBridge.call("closeWindow");
                },
                false
            );
            //这个可以关闭ios系统的手机
            window.WeixinJSBridge.call("closeWindow");
        }, 1);
    }
  }
}
</script>

<style scoped>

  .field_vlaue{
    color: #888;
  }
</style>
