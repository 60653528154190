<template>
  <div style="width: 100%; margin: 0; padding: 0;">
    <div style="width: 100%; height:  calc(100vh - 0); line-height: 25px;margin: 0 auto;  overflow-y: auto; ">
      <van-form ref="familyForm" style="width: 100%;">
        
        <!--教育经历-->
        <div class="header">
          <div class="rect"></div>
          <div class="title" v-if="form.type == 1">添加家庭成员</div>
          <div class="title" v-if="form.type == 2">添加紧急联系人</div>
        </div>
        <van-cell-group inset v-if="form.type == 1">
            <van-field
              v-model="form.name"
              name="姓名"
              label="姓名"
              :rules="[{ required: true, message: '请填写成员姓名' }]"
            />
            <van-field
              v-model="form.relation"
              name="关系"
              label="关系"
              :rules="[{ required: true, message: '请填写与您的关系' }]"
            />
            <van-field
              v-model="form.workUnit"
              name="工作单位"
              label="工作单位"
              :rules="[{ required: true, message: '请填写工作单位' }]"
            />
            <van-field
              v-model="form.job"
              name="职业"
              label="职业"
              :rules="[{ required: true, message: '请填写职业' }]"
            />
            <van-field
              v-model="form.phone"
              name="联系电话"
              label="联系电话"
              :rules="[{ required: true, message: '请填写联系电话' }]"
            />
        </van-cell-group>
        <van-cell-group inset v-if="form.type == 2">
            <van-field
              v-model="form.name"
              name="联系人姓名"
              label="联系人姓名"
              :rules="[{ required: true, message: '请填写联系人姓名' }]"
            />
            <van-field
              v-model="form.relation"
              name="关系"
              label="关系"
              :rules="[{ required: true, message: '请填写与您的关系' }]"
            />
            <van-field
              v-model="form.phone"
              name="联系电话"
              label="联系电话"
              :rules="[{ required: true, message: '请填写联系电话' }]"
            />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block plain type="info" native-type="submit" @click="back" style="width: 25%; float: left;">
          返回
          </van-button>
          <van-button round block type="info" native-type="submit" @click="onSubmit" style="width: 70%; float: right;">
          保存
          </van-button>
        </div>
      </van-form>
    </div>
    <!--出生日期选择-->
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        @confirm="onDateConfirm"
        @cancel="showDate = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
import { Field, Row, Col, Button, Empty, List, Form, Sticky, Image, CellGroup, RadioGroup, Radio, Picker, Popup, Checkbox, CheckboxGroup, Step, Steps, Uploader, Calendar, DatetimePicker     } from 'vant'
Vue.use(Sticky)
Vue.use(Field)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Empty)
Vue.use(List)
Vue.use(Form)
Vue.use(Image)
Vue.use(CellGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Toast );
Vue.use(CheckboxGroup );
Vue.use(Checkbox );
Vue.use(Steps);
Vue.use(Step);
Vue.use(Uploader);
Vue.use(Calendar );
Vue.use(DatetimePicker);



export default {
  name: "whqAddFamily",
  data() {
    return {
      // 教育、工作经历表单
      form: {
        baseInfoId: '',
        type: 1,
        dateRange: '',
        beginDate: '',
        endDate: '',
        unit: '',
        major: '',
        result: ''
      },
      successImg: require('@/assets/success.png'),
      failImg: require('@/assets/fail.png'),
      loadingImg: require('@/assets/loading.png'),
      showcardTypePicker: false, // 证件类型
      currentDate: new Date(), // 现在时间
      type: 'datetime',
      currentDateField: '', // 当前正在修改的日期字段，用于表单多日期组件选择的情况
      newUserFlag: false, // 新用户标识，如果未保存过信息该标识为ture
      showDate: false //是否展示日期选择框
    }
  },
  mounted: function() {
    this.form.baseInfoId = this.$route.query.id;
    this.form.type = this.$route.query.type;
    console.log(this.$route.query.id);
  },
  methods: {
    // 提交
    onSubmit(){
      let _this = this;
      // console.log("进入submit" + this.guarantee);
      this.$refs.familyForm.validate()
        .then(() => {
          Toast.loading({
            forbidClick: true
          })
          this.form.baseInfoId = this.$route.query.id;
          console.log(this.form);
          this.form.dateRange = this.form.beginDate + "——" + this.form.endDate;
          this.$axios.post('/ework-inside/hr/familyLink/add', this.form)
            .then(res => {
              Toast.clear();
              console.log(res);
              if(res.data.code == 0) {
                Toast({
                  message: '添加成功',
                  type: 'success',
                  onClose: function() {
                    console.log(123)
                    // _this.$router.go(-1)
                    _this.$router.back();
                  }
                });
              }
              // 加载用户数据
            }).catch(() => {
              Toast.clear()
            })
        })
        .catch(() => {
          Toast("请按要求填写表单");
        });   
    },
    back(){
      this.$router.back();
    },
    onDateConfirm(val){
      this.showDate = false;

      let dateValue = ''
      dateValue = this.formatDate(val, 'yyyy-MM-dd')
      this.form[this.currentDateField] = dateValue;
    },
    formatDate(val, format) {
      const REGEX = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/
      if (val) {
        /**
         * @instructions 如果不是时间戳格式，且含有字符 '-' 则将 '-' 替换成 '/' && 删除小数点及后面的数字
         * @reason 将 '-' 替换成 '/' && 删除小数点及后面的数字 的原因是safari浏览器仅支持 '/' 隔开的时间格式
         */
        if (val.toString().indexOf('-') > 0) {
          val = val.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').replace(/(-)/g, '/') // 将 '-' 替换成 '/'
          val = val.slice(0, val.indexOf('.')) // 删除小数点及后面的数字
        }
        const date = new Date(val)
        date.setHours(date.getHours() + 8)
        // eslint-disable-next-line no-unused-vars
        const [whole, yy, MM, dd, hh, mm, ss] = date.toISOString().match(REGEX)
        if (format) {
          return format
            .replace('yyyy', yy)
            .replace('yy', yy.slice(2))
            .replace('MM', MM)
            .replace('dd', dd)
            .replace('hh', hh)
            .replace('mm', mm)
            .replace('ss', ss)
        } else {
          return [yy , MM , dd].join('-') + ' ' + [hh, mm, ss].join(':')
        }
      } else {
        return '--'
      }
    },
    showDatePop(obj){
      this.showDate = true;
      this.currentDateField = obj;
      console.log(this.currentDateField);
      
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%; 
  height: 50px; 
  line-height: 50px;
}
.header>.rect {
  width: 5px; 
  height: 15px; 
  background: #0486FE; 
  float: left; 
  margin-top: 17px;
}
.header>.title {
  width: 200px; 
  height: 20px; 
  float: left; 
  margin-left: 10px; 
  font-size: 18px;
}

.field_vlaue{
  color: #888;
}

</style>
